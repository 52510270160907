<template>
    <router-link :to="{name: 'server', params: {uuid: server.uuid}}" :style="{ cursor: 'pointer'}" tag="tr">
        <td v-if="sponsored" style="width: 12%"><i class="fas fa-star"></i></td>
        <td v-else style="width: 12%"> # {{ position }}</td>
        <td style="width: 60%">
            <h6 class="text-left mb-0 text-uppercase">{{ server.name }}</h6>
            <hr class="mt-0">
            <img alt="server-banner" class="adaptar-img " v-bind:src="server.banner">
            <div class="p-1 pl-2 pr-2 bg-dark text-light d-flex justify-content-between">
                <h6 class="mt-2" style="font-size: 13px">
                    <i class="fas fa-satellite-dish ml-1 mr-2"></i>
                    {{ server.address }}&nbsp;
                </h6>
                <b-button v-if="supportsCB" @click.prevent="copy(server.address)" type="button" size="sm"
                          variant="outline-secondary" class="custom-button text-light border-0 shadow-none">
                    <i class="fas fa-copy fa-sm mr-2"></i>&nbsp;Copy
                </b-button>
            </div>
        </td>
        <td style="width: 14%; font-size:15px;" v-if="server.last_stats">
            {{ server.last_stats.connected_clients }} / {{ server.last_stats.max_allowed_clients }}
        </td>
        <td style="width:25%;" v-if="server.last_stats">
            <div v-if="server.last_stats.up">
                <b-button disabled variant="outline-success" style="color:forestgreen;  font-size: 13px;">
                    <i class="fas fa-check mr-2"></i> Online
                </b-button>
            </div>
            <div v-else>
              <b-button disabled variant="outline-success" style="color:crimson; font-size: 13px;">
                <i class="fas fa-times mr-2"></i> Offline
              </b-button>
            </div>
        </td>
    </router-link>
</template>
<script>
    import {ApiService} from "./ApiService";
    import {BButton} from "bootstrap-vue"

    const apiService = new ApiService();

    export default {
        name: 'ServerTR',
        props: ['server', 'position', 'sponsored'],
        components: {
            BButton
        },
        data() {
            return {
                supportsCB: false,
                apiService: apiService,
            }
        },
        created() {
            if (navigator.clipboard) {
                this.supportsCB = true;
            }
        },
        methods: {

            makeToast(variant = null, text, server) {
                this.$bvToast.toast('IP: ' + server, {
                    title: text,
                    toaster: 'b-toaster-bottom-right',
                    variant: variant,
                    solid: true
                })
            },
            copy: function (serverAddress) {
                navigator.clipboard.writeText(serverAddress)
                    .then(() => {
                        this.makeToast('success', 'Code copied to clipboard', serverAddress);
                    })
                    .catch(e => {
                        this.makeToast('danger', e + 'Sorry, unable to copy to clipboard.');
                    });
            }
        }
    }
</script>

<style scoped>

    .custom-button:hover, .custom-button:focus, .custom-button:active {
        color: #6c757d;
        background-color: transparent;
    }

    .adaptar-img {
        width: 100%;
        max-height: 4em;
    }

    .table td {
        vertical-align: middle;
        text-align: center;
    }

    tr {
        border: 1px solid #dee2e6;
    }
</style>
